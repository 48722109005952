<template>
  <select class="q-ml-md" @change="updateLanguage()" v-model="$i18n.locale">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale.toUpperCase() }}
    </option>
  </select>
</template>

<script>
import axios from "../../../services/Api";
import { mapActions } from "vuex";


export default {
  data() {
    return { locales: ["es", "en", "cat", "it"] };
  },
  methods: {

    ...mapActions("auth", ["setUser"]),

    updateLanguage() {
      let sendLocale = this.$i18n.locale == "cat"? "ca" : this.$i18n.locale;
      if (this.$store.state.auth.authenticated) {
        axios
          .put("front-catalog/customers", {
            locale: sendLocale
          }).then(() => {
            this.setUser();
          })
          .catch(() => {
            this.failEditDialog = true;
          })
          .finally(() => {
            this.successEditDialog = true;
          });
      }
    },
  }


};
</script>

<style></style>
